import React from "react";
import classes from "./index.module.scss";

// @ts-ignore
import video from "../../res/videos/background.mp4";

// @ts-ignore
import leaf from "../../res/videos/leaf.mp4";
import submitDetails from "../../util/submitDetails";
import R from "../../res";
import validateEmail from "../../util/validateEmail";
import TermsOfService from "../../components/TermsOfService";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import Loader from "../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { firebaseAnalytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

const HomePage = () => {
  const [showTermsOfService, setShowTermsOfService] = React.useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const links = React.useMemo(
    () => [
      {
        href: "https://twitter.com/herbanapp",
        icon: R.images.default.icTwitter,
        name: "Twitter",
      },
      {
        href: "https://www.linkedin.com/company/herbanapp/",
        icon: R.images.default.icLinkedin,
        name: "LinkedIn",
      },
      {
        href: "https://facebook.com/herbanapp",
        icon: R.images.default.icFacebook,
        name: "Facebook",
      },
    ],
    []
  );

  React.useEffect(() => {
    logEvent(firebaseAnalytics, "page_view", {
      page_path: "/",
    });
  }, []);

  const canProceed = React.useMemo(() => {
    if (email.trim().length === 0 || name.trim().length === 0) return false;
    return validateEmail(email);
  }, [email, name]);

  const sendForm = React.useCallback(async () => {
    if (!canProceed || isLoading) return;

    setIsLoading(true);
    await submitDetails({
      email: email.trim(),
      name: name.trim(),
    });

    setEmail("");
    setName("");
    setIsLoading(false);
  }, [canProceed, isLoading, email, name]);

  React.useEffect(() => {
    // if (window.location.href.includes("terms-of-service")) {
    //   setShowTermsOfService(true);
    // }

    // if (window.location.href.includes("privacy-policy")) {
    //   setShowPrivacyPolicy(true);
    // }

    if (location.pathname === "/terms-of-service") {
      setShowTermsOfService(true);
    }

    if (location.pathname === "/privacy-policy") {
      console.log("here");
      setShowPrivacyPolicy(true);
    }
  }, [location.pathname]);

  return (
    <>
      <TermsOfService
        isVisible={showTermsOfService}
        onClose={() => {
          setShowTermsOfService(false);
          navigate(-1);
        }}
      />
      <PrivacyPolicy
        isVisible={showPrivacyPolicy}
        onClose={() => {
          setShowPrivacyPolicy(false);
          navigate(-1);
        }}
      />
      <div className={classes["container"]}>
        <img
          src={R.images.default.ilBackground}
          className={classes["background-image"]}
          alt="Background"
        />
        <video
          className={classes["video"]}
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={video} />
        </video>

        <video
          className={classes["top-left-leaf"]}
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={leaf} />
        </video>
        <video
          className={classes["top-right-leaf"]}
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={leaf} />
        </video>
        <video
          className={classes["bottom-right-leaf"]}
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={leaf} />
        </video>
        <video
          className={classes["bottom-left-leaf"]}
          controls={false}
          autoPlay
          muted
          loop
        >
          <source src={leaf} />
        </video>
        <div className={classes["header"]}>
          <img
            src={R.images.default.ilLogo}
            className={classes["logo"]}
            alt="Logo"
          />
          <p className={classes["logo-text"]}>HerbanApp</p>
        </div>
        <div className={classes["content"]}>
          <p className={classes["text-1"]}>looking for weed?</p>
          <p className={classes["title"]}>
            Find the best joint near you with{" "}
            <span>
              HerbanApp.
              <img src={R.images.default.ilUnderline} alt="Underline" />
            </span>
          </p>
          <p className={classes["text-2"]}>Be prepared for our launch soon.</p>
          <div className={classes["links"]}>
            {links.map((link) => (
              <a
                href={link.href}
                className={classes["link"]}
                target="_blank"
                rel="noopener noreferrer"
                key={link.name}
              >
                <img
                  src={link.icon}
                  alt={link.name}
                  className={classes["icon"]}
                />
              </a>
            ))}
          </div>
        </div>
        <div className={classes["bottom"]}>
          <p className={classes["label"]}>Learn first when the app launches.</p>
          <div className={classes["inputs"]}>
            <div className={classes["input-container"]}>
              <p>Name</p>
              <input
                type="text"
                placeholder="Enter your first name.."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={classes["input-container"]}>
              <p>Email Address</p>
              <input
                type="text"
                placeholder="Enter your email address.."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button
              className={classes["button"]}
              disabled={!canProceed || isLoading}
              onClick={sendForm}
            >
              {isLoading ? (
                <Loader color="#fff" />
              ) : (
                <>
                  <svg
                    fill="transparent"
                    pointerEvents="none"
                    viewBox="0 0 20 20"
                    width={16}
                    height={16}
                  >
                    <path
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                      fill="#fff"
                    />
                  </svg>
                  <p>Submit</p>
                </>
              )}
            </button>
          </div>
          <div className={classes["terms-row"]}>
            <button
              className={classes["button"]}
              onClick={() => navigate("/terms-of-service")}
            >
              <p>Terms of Service</p>
            </button>
            <button
              className={classes["button"]}
              onClick={() => navigate("/privacy-policy")}
            >
              <p>Privacy Policy</p>
            </button>
            <button
              className={classes["button"]}
              onClick={() => navigate("/contact")}
            >
              <p>Contact</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
