import React from "react";
import Modal from "../Modal";
import classes from "./index.module.scss";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const TermsOfService = (props: Props) => {
  return (
    <Modal isVisible={props.isVisible} onClose={props.onClose}>
      <p className={classes["label"]}>legal pages</p>
      <p className={classes["title"]}>Terms of Service</p>

      <p>
        The website located at www.herbanapp.com (the “Site”) is a copyrighted
        work belonging to HerbanApp LLC. (“HerbanApp,” “Us,”“Our,” and/or “We”),
        and its affiliates, subsidiaries, parent company, and other related
        companies. HerbanApp provides in-store product review and ordering
        services via kiosks at dispensaries, as well as websites, including
        herban.app, herbanapp.com, and related sub-domains, mobile and/or
        software applications that host content related to cannabis varieties
        and related products which include reviews and ratings provided by its
        users, directories of cannabis dispensaries and medical providers, and
        cannabis-related news stories and other articles (collectively, with all
        other services provided by HerbanApp, the “Services”). All such
        additional terms, guidelines, and rules are incorporated by reference
        into this Agreement. References to “user,” “you” and “your” refer to
        you, a user of our Site and/or Services.
      </p>

      <p>
        THESE TERMS OF USE (“AGREEMENT”) SETS FORTH THE LEGALLY BINDING TERMS
        FOR YOUR USE OF THE SITE AND SERVICES. BY ACCESSING OR USING THE SITE OR
        SERVICES, YOU ARE ACCEPTING THIS AGREEMENT AND YOU REPRESENT AND WARRANT
        THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS
        AGREEMENT. YOU MAY NOT ACCESS OR USE THE SITE OR SERVICES OR ACCEPT THE
        AGREEMENT IF YOU DO NOT HAVE THE CAPACITY TO ENTER INTO THIS AGREEMENT.
        IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT
        ACCESS AND/OR USE THE SITE OR SERVICES. IF YOU ARE USING THE SITE OR
        SERVICES ON BEHALF OF A COMPANY, ENTITY, OR ORGANIZATION, YOU REPRESENT
        AND WARRANT THAT YOU ARE AN AUTHORIZED REPRESENTATIVE OF SUCH COMPANY,
        ENTITY, OR ORGANIZATION WITH THE AUTHORITY TO BIND IT TO THIS AGREEMENT.
      </p>

      <p>
        PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN AN AGREEMENT TO
        ARBITRATE AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
        REMEDIES, AND OBLIGATIONS. THE AGREEMENT TO ARBITRATE REQUIRES (WITH
        LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING
        AND FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED TO
        PURSUE CLAIMS AGAINST COMPANY ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF
        OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND
        (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY,
        INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS.
      </p>

      <p className={classes["section-title"]}>1. IMPORTANT DISCLAIMERS.</p>
      <p>
        ALL INFORMATION CONTAINED ON THE SITE AND SERVICES IS FOR INFORMATIONAL
        PURPOSES ONLY. CONTENT POSTED BY HERBANAPP ON ANY WEBSITE, KIOSK, MOBILE
        APPLICATION, SOCIAL MEDIA CHANNEL, THIRD-PARTY CONTENT SERVICE, OR
        ADVERTISEMENT IS FOR INFORMATIONAL PURPOSES ONLY. HERBANAPP DOES NOT
        ENDORSE, AND IS NOT RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF, ANY
        OPINION, ADVICE, STATEMENT, OR OTHER INFORMATION MADE ON THE SITE OR
        SERVICES, INCLUDING USER CONTENT AND THIRD-PARTY MATERIALS (EACH AS
        DEFINED BELOW). HERBANAPP IS NOT RESPONSIBLE FOR YOUR RELATIONSHIP WITH
        ANY DISPENSARY, RETAIL LOCATION, HEALTH CARE PROVIDER, ANY THIRD-PARTY,
        OR OTHER USERS OF THE SITE OR SERVICES. HERBANAPP IS NOT OBLIGATED TO
        SCREEN DISPENSARIES, RETAIL LOCATIONS, HEALTH CARE PROVIDERS, OR THEIR
        MENUS, CONTENT, OR DEALS TO DETERMINE WHETHER THEY ARE QUALIFIED OR
        AUTHORIZED BY LAW TO PROVIDE THEIR SERVICES OR TO DETERMINE THE ACCURACY
        OF THEIR MENUS OR OTHER INFORMATION THEY PROVIDE.
      </p>

      <p>
        ● HERBANAPP DOES NOT OFFER MEDICAL ADVICE. ANY INFORMATION ACCESSED
        THROUGH THE SITE AND SERVICES, OR WITHIN ANY OF HERBANAPP’S SOCIAL MEDIA
        PAGES OR CHANNELS IS FOR INFORMATIONAL AND EDUCATIONAL PURPOSES ONLY, IS
        NOT INTENDED TO BE A SUBSTITUTE FOR MEDICAL ADVICE, DIAGNOSIS, OR
        TREATMENT, AND IS NOT INTENDED TO COVER ALL POSSIBLE USES, DIRECTIONS,
        PRECAUTIONS, OR ADVERSE EFFECTS. SUCH INFORMATION INCLUDES, WITHOUT
        LIMITATION, THIRD-PARTY MATERIALS, USER CONTENT, AND HERBANAPP-GENERATED
        CONTENT DERIVED FROM USER CONTENT (E.G., STRAIN HIGHLIGHTS, ATTRIBUTES,
        AND OTHER DATA). THE INFORMATION ON THE SITE AND SERVICES AND PROVIDED
        VIA HERBANAPP’S SOCIAL MEDIA PAGES AND CHANNELS SHOULD NOT BE USED FOR
        THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION. ALWAYS CONSULT A
        QUALIFIED HEALTH CARE PROVIDER IF YOU HAVE ANY QUESTIONS ABOUT A MEDICAL
        CONDITION. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN
        SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ ON THE SITE, SERVICES, OR
        ON HERBANAPP’S SOCIAL MEDIA PAGES AND CHANNELS
      </p>
      <p>
        ● ACKNOWLEDGEMENT OF FEDERAL LAW: User expressly acknowledges that
        herbanapp is for residents of states and localities with laws regulating
        medical or the recreational use of cannabis only and that medical
        cannabis collectives and patients are established pursuant to their
        respective local laws. Marijuana is included on Schedule 1 under the
        United States Controlled Substances Act. Under the federal laws of the
        United States of America, manufacturing, distributing, dispensing or
        possession of marijuana is illegal, and individuals are subject to
        arrest and/or prosecution for doing so. User further acknowledges that
        medical use is not recognized as a valid defense under federal laws
        regarding marijuana. User also acknowledges that the interstate
        transportation of marijuana is a federal offense. THE FOREGOING
        DISCLAIMERS AND LIMITATIONS ON LIABILITY SHALL NOT LIMIT THE MORE
        GENERAL DISCLAIMERS AND LIMITATIONS ON LIABILITY IN SECTIONS 9 AND 10 OR
        ELSEWHERE IN THIS AGREEMENT.
      </p>

      <p className={classes["section-title"]}>2. ELIGIBILITY AND ACCOUNTS.</p>
      <p>
        2.1 Eligibility. You must be 21 years of age or a qualified medical
        marijuana patient to use the Site and/or Services within the United
        States.
      </p>
      <p>
        2.2 Account Creation. In order to use certain features of the Site
        (e.g., to use the Services), you must register for an account with
        HerbanApp (“HerbanApp Account”) and provide certain information about
        yourself as prompted by the Site registration form. You represent and
        warrant that: (a) all required registration information you submit is
        truthful and accurate; and (b) you will maintain the accuracy of such
        information at all times. You may not create more than one HerbanApp
        Account. herbanApp may suspend or terminate your HerbanApp Account in
        accordance with Sections 6.4 and 11.
      </p>
      <p>
        2.3 Account Deletion. You may delete your herbanapp Account at any time,
        for any reason, by sending an email to support@herban.app which includes
        your HerbanApp Account Username and your request to delete your account.
      </p>
      <p>
        2.4 Account Responsibilities. You are responsible for maintaining the
        confidentiality of your HerbanApp Account login information and are
        fully responsible for all activities that occur under your HerbanApp
        Account. You agree to immediately notify HerbanApp of any unauthorized
        use, or suspected unauthorized use, of your HerbanApp Account or any
        other breach of security. HerbanApp cannot and will not be liable for
        any loss or damage arising from your failure to comply with the above
        requirements.
      </p>

      <p>
        2.5 Social Networking Services. Alternatively, we may permit you to
        login to the Site or Service or otherwise associate your HerbanApp
        Account with your login credentials from certain social networking sites
        (e.g., Facebook and Twitter) (“SNS”). If you log in or otherwise
        associate your HerbanApp Account with your login credentials from a SNS,
        we may receive information about you from such SNS, in accordance with
        the terms and conditions (e.g., terms of use and privacy policy) of the
        SNS (“SNS Terms”). If you elect to share your information with these
        SNS, we will share information with them in accordance with your
        election. The SNS Terms of said SNS will apply to the information we
        disclose to them.
      </p>
      <p className={classes["section-title"]}>3. SITE AND MOBILE APP</p>
      <p>
        3.1 License. Subject to the terms of this Agreement, HerbanApp grants
        you a non-transferable, non-exclusive license to use the Site and
        Services for your personal, noncommercial use. Subject to the terms of
        this Agreement, HerbanApp grants you a non-transferable, non-exclusive
        license to install and use the software herbanapp makes available for
        mobile devices (“Mobile App”), in executable object code format only,
        solely on your own handheld mobile device and for your personal,
        noncommercial use. As used in this Agreement, the term “Services”
        includes the Mobile App.
      </p>
      <p>
        3.2 Certain Restrictions. The rights granted to you in this Agreement
        are subject to the following restrictions: (a) you shall not license,
        sell, rent, lease, transfer, assign, distribute, host, or otherwise
        commercially exploit the Site or Services; (b) you shall not modify,
        make derivative works of, disassemble, reverse compile or reverse
        engineer any part of the Site or Services; (c) you shall not access the
        Site or Services in order to build a similar or competitive service; and
        (d) except as expressly stated herein, no part of the Site or Services
        may be copied, reproduced, distributed, republished, downloaded,
        displayed, posted or transmitted in any form or by any means. Any future
        release, update, or other addition to functionality of the Site or
        Services shall be subject to the terms of this Agreement. All copyright
        and other proprietary notices on any Site or Services content must be
        retained on all copies thereof.
      </p>
      <p>
        3.3 Modification. HerbanApp reserves the right, at any time, to modify,
        suspend, or discontinue the Site or Services or any part thereof with or
        without notice. You agree that HerbanApp will not be liable to you or to
        any third party for any modification, suspension, or discontinuance of
        the Site or Services or any part thereof.
      </p>
      <p>
        3.4 Ownership. Excluding User Content, you acknowledge that all the
        intellectual property rights in the Site and Services are owned by
        HerbanApp or HerbanApp’s licensors. The provision of the Site and
        Services does not transfer to you or any third party any rights, title,
        or interest in or to such intellectual property rights. HerbanApp and
        its suppliers reserve all rights not granted in this Agreement.
      </p>
      <p>
        3.5 App Platforms. You acknowledge and agree that the availability of
        the Mobile App is dependent on the third-party app platform from which
        you received the Mobile App, e.g., the Apple App Store® or the Google
        Play® Store (“App Platform”). You acknowledge that this Agreement is
        between you and HerbanApp and not with the App Platform. HerbanApp, not
        the App Platform, is solely responsible for the App, the content
        thereof, maintenance, support services, and warranty therefor, and
        addressing any claims relating thereto (e.g., product liability, legal
        compliance, or intellectual property infringement). You agree to pay all
        fees charged by the App Platform in connection with the Mobile App. Each
        App Platform may have its own terms and conditions to which you must
        agree before downloading the Mobile App from it. You agree to comply
        with, and your license to use the Mobile App is conditioned upon your
        compliance with, all applicable agreements, terms, and conditions of
        use/service, and other policies of the applicable App Platform. You
        acknowledge that the App Platform (and its subsidiaries) is a
        third-party beneficiary of this Agreement and will have the right to
        enforce this Agreement. See Accessing and Downloading the Application
        from Apple® in Section 14.5 below for additional terms and conditions if
        you access or download the Mobile App from the Apple App Store.
      </p>

      <p className={classes["section-title"]}>4. COMMUNICATIONS</p>
      <p>
        4.1 Text Messaging. By using the Services or Software, you agree and
        consent to HerbanApp and those acting on its behalf sending you text
        (SMS) messages at the phone number you provided us. These messages may
        include operational messages about your use of the Services, as well as
        marketing or other promotional messages. Messages from HerbanApp, its
        affiliated companies and necessary third-party service providers, may
        include but are not limited to: operational communications concerning
        your User account or use of the Services, updates concerning new and
        existing features on HerbanApp, communications concerning promotions run
        by us or our third-party partners, and news concerning HerbanApp and
        industry developments. Standard text messaging charges applied by your
        cell phone carrier will apply to text messages we send. Your agreement
        to receive promotional texts is not a condition of purchase of any goods
        or services offered by HerbanApp. If you change or deactivate the phone
        number you provided to HerbanApp, you must update your account
        information to help prevent us from inadvertently communicating with
        anyone who acquires your old number. You agree that texts, calls, or
        prerecorded messages may be generated by automatic telephone dialing
        systems and hereby waive your right to pursue any claims arising under
        the Telephone Consumer Protection Act (“TCPA”). To the extent any claim
        under the TCPA is deemed un-waivable, you agree that by using the
        Services or accessing the Site, you are agreeing that any such
        un-waivable claim arising under the TCPA will be arbitrated on an
        individual, not class or representative, basis, as set forth in
        Paragraph 13.
      </p>

      <p>
        4.2 Opt-Out. By signing this agreement, you are agreeing to receive text
        messages until you opt-out. You may opt out of receiving promotional
        text messages from HerbanApp at any time by replying STOP to a
        promotional text message from herbanapp. You may opt-out of receiving
        all text messages from HerbanApp at any time by deleting your account or
        by replying STOP to any text message from HerbanApp. NOTE: if you
        opt-out of receiving all text messages from HerbanApp, you will not be
        able to use certain Services without agreeing to receive operational
        text messages. You may continue to receive text messages for a short
        period while HerbanApp processes your request, and you may also receive
        text messages confirming the receipt of your opt-out request. By signing
        this agreement, you are waiving your right to pursue any claims arising
        under the TCPA related to any messages sent to you while the opt-out
        request is pending. To the extent any claim under the TCPA is deemed
        un-waivable, you agree that by using the Services or accessing the Site,
        you are agreeing that any such un-waivable claim arising under the TCPA
        will be arbitrated on an individual, not class or representative, basis,
        as set forth in Paragraph 13.
      </p>
      <p>
        4.3 Opting Back In. You may opt back into receiving text messages from
        HerbanApp at any time by replying START to a text message from
        HerbanApp.
      </p>
      <p>
        4.4 Push Notifications. When you install our Mobile App on your mobile
        device you agree to receive push notifications, which are messages an
        app sends you on your mobile device even when the Mobile App is not on.
        You can turn off notifications by visiting your mobile device’s
        “settings” page.
      </p>
      <p>
        4.5 Email. You agree that we may send you emails concerning our products
        and services, as well as those of third parties. You may opt-out of
        promotional emails by following the unsubscribe instructions in a
        promotional email.
      </p>

      <p className={classes["section-title"]}>5. USER CONTENT</p>
      <p>
        5.1 User Content. “User Content” means any and all information and
        content that a user submits to, or uses with, the Site or Services,
        including without limitation, content in the user’s profile, user
        reviews and/or postings. You acknowledge and agree that HerbanApp is not
        responsible for any User Content, including its accuracy, completeness,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect thereof. herbanapp does not assume and will not have
        any liability or responsibility to you or any other person or user for
        your use or misuse of any User Content.
      </p>
      <p>
        5.2 User Content – Restrictions. You agree not to use the Site,
        Services, or any of HerbanApp’s social media pages or channels to
        collect, upload, transmit, display, or distribute any User Content that
        (a) violates any third-party right, including any copyright, trademark,
        patent, trade secret, moral right, privacy right, right of publicity, or
        any other intellectual property or proprietary right; (b) is unlawful,
        harassing, abusive, tortious, threatening, harmful, invasive of
        another’s privacy, vulgar, defamatory, false, intentionally misleading,
        trade libelous, pornographic, obscene, patently offensive (e.g.,
        material that promotes racism, bigotry, hatred, or physical harm of any
        kind against any group or individual) or otherwise objectionable
        material of any kind or nature; or (c) in violation of any law,
        regulation, or obligations or restrictions imposed by any third-party.
      </p>
      <p>
        5.3 User Content – Your Responsibilities. You are solely responsible for
        your User Content. You assume all risks associated with use of your User
        Content, including any reliance on its accuracy, completeness or
        usefulness by others, or any disclosure of your User Content that makes
        you or any third-party personally identifiable. You hereby represent and
        warrant that your User Content does not violate the Acceptable Use
        Policy (defined below). You may not state or imply that your User
        Content is in any way provided, sponsored, or endorsed by herbanapp. You
        acknowledge and agree that herbanapp is not responsible for any User
        Content, including its accuracy, completeness, timeliness, validity,
        copyright compliance, legality, decency, quality, or any other aspect
        thereof. herbanapp does not assume and will not have any liability or
        responsibility to you or any other person or user for your use or misuse
        of any User Content. Because you alone are responsible for your User
        Content (and not HerbanApp), you may expose yourself to liability if,
        for example, your User Content violates the Acceptable Use Policy or
        other applicable laws. HerbanApp is not obligated to backup any User
        Content and User Content may be deleted at any time. You are solely
        responsible for creating backup copies of your User Content, if you
        desire.
      </p>

      <p>
        5.4 License. You hereby grant, and you represent and warrant that you
        have the right to grant, to HerbanApp an irrevocable, nonexclusive,
        royalty-free and fully-paid, worldwide license to reproduce, distribute,
        publicly display and perform, prepare derivative works of, incorporate
        into other works, and otherwise use your User Content, and to grant
        sublicenses of the foregoing, solely for the purposes of including your
        User Content in the Site and Services. You agree to irrevocably waive
        (and cause to be waived) any claims and assertions of moral rights or
        attribution with respect to your User Content.
      </p>
      <p>
        5.5 Feedback. If you provide HerbanApp any feedback or suggestions
        regarding the Site or Services (“Feedback”), you hereby assign to
        HerbanApp all rights in the Feedback and agree that HerbanApp shall have
        the right to use such Feedback and related information in any manner it
        deems appropriate. HerbanApp will treat any Feedback you provide to
        HerbanApp as non-confidential and non-proprietary. You agree that you
        will not submit to HerbanApp any information or ideas that you consider
        to be confidential or proprietary.
      </p>

      <p className={classes["section-title"]}>6. ACCEPTABLE USE POLICY.</p>
      <p>The following sets forth HerbanApp’s “Acceptable Use Policy”:</p>
      <p>
        6.1 Reviews. You must have a valid account and email address to leave a
        review on HerbanApp. Prior to posting a review, you will need to verify
        your email address associated with your HerbanApp account. You agree not
        to post reviews on the Site, Services, or any of HerbanApp’s social
        media pages or channels that are not based upon your personal experience
        or are otherwise designed for any purpose other than providing other
        users on the Site with an accurate description of your personal
        experience. Reviews based on secondhand, non-personal experience are not
        allowed. In addition to the other restrictions contained in the
        Acceptable Use Policy, reviews posted on the Site, Services, or any of
        HerbanApp’s social media pages or channels must not: (a) be written
        exclusively in capital letters; (b) be plagiarized; (c) contain spam,
        advertisements, and/or links to external websites; (d) contain
        disparaging information about any Dispensary employees or any other
        person, (e) contain overly detailed or sexual descriptions of an
        individual’s physical appearance, or lewd personal attacks against a
        specific individual or group of individuals affiliated with the
        Dispensary, (f) contain references to Dispensaries or competitors other
        than the product or Dispensary being reviewed, or (g) contain unrelated
        personal grievances. If you are an owner, volunteer, or employee of a
        Dispensary you may not post reviews about your Dispensary or products or
        about your competitors’ Dispensary or products. As an owner of a
        Dispensary, you are responsible for moderating and enforcing the
        Acceptable Use Policy on all reviews posted to your Dispensary page.
      </p>
      <p>
        6.2 Photos. Image files must exclusively feature the products they
        illustrate and must not include body parts, messy or cluttered
        backgrounds, product wrapping, currency, paraphernalia, brand names, or
        other any objects other than the product itself. Image files must be
        clear and must not be blurry, fuzzy, or contain any flash reflections.
        Products should be centered in the image file. Image files must contain
        an accurate depiction of the product they illustrate. Image files cannot
        contain pornography or other graphic images and must otherwise abide by
        the guidelines set forth in this Section 6.
      </p>
      <p>
        6.3 Technological Restrictions. In addition, you agree not to use the
        Site or Services to: (a) upload, transmit, or distribute any computer
        viruses, worms, or any software intended to damage or alter a computer
        system or data; (b) send unsolicited or unauthorized advertising,
        promotional materials, junk mail, spam, chain letters, pyramid schemes,
        or any other form of duplicative or unsolicited messages, whether
        commercial or otherwise; (c) harvest, collect, gather or assemble
        information or data regarding other users, including e-mail addresses,
        without their consent; (d) interfere with, disrupt, or create an undue
        burden on servers or networks connected to the Site or Services or
        violate the regulations, policies or procedures of such networks; (e)
        attempt to or impersonate another user or HerbanApp or gain unauthorized
        access to the Site or Services, other computer systems or networks
        connected to or used together with the Site or Services, through
        password mining or other means; (f) harass or interfere with another
        user’s use and enjoyment of the Site or Services; or (g) introduce
        software or automated agents or scripts to the Site or Services so as to
        produce multiple accounts, generate automated searches, requests and
        queries, or to strip, scrape, or mine data from the Site or Services.
      </p>
      <p>
        6.4 Monitoring, Suspension, and Termination. We reserve the right (but
        have no obligation) to review any User Content (including any image
        files or reviews), investigate, and/or take appropriate action against
        you in our sole discretion (including removing or modifying your User
        Content, terminating your HerbanApp Account in accordance with Section
        11, and/or reporting you to law enforcement authorities) if we in our
        sole discretion suspect that you have violated the Acceptable Use Policy
        or any other provision of this Agreement or otherwise create liability
        for us or any other person.
      </p>

      <p className={classes["section-title"]}>7. INDEMNITY.</p>
      <p>
        You agree to indemnify and hold HerbanApp (and its officers, employees,
        and agents) harmless from any and all losses, damages, liabilities,
        claims, actions, judgments, awards, penalties, fines, costs and/or
        expenses (including reasonably attorneys’ fees) arising from or relating
        to any claim or demand made by any third party due to or arising out of
        (i) your use or misuse of the Site or Services, (ii) your User Content,
        (iii) your violation of this Agreement; or (iv) your violation of
        applicable laws or regulations. HerbanApp reserves the right, at your
        expense, to assume the exclusive defense and control of any matter for
        which you are required to indemnify us and you agree to cooperate with
        our defense of these claims. You agree not to settle any matter without
        the prior written consent of HerbanApp. HerbanApp will use reasonable
        efforts to notify you of any such claim, action or proceeding upon
        becoming aware of it.
      </p>

      <p className={classes["section-title"]}>
        8. THIRD-PARTY INTERACTIONS; THIRD-PARTY MATERIALS; DEALS; MENUS; OTHER
        USERS; RELEASE.
      </p>
      <p>
        8.1 Third-Party Interactions. During use of the Site or Service, you may
        enter into correspondence with, purchase goods and/or services from, or
        participate in promotions of third-party service providers, advertisers,
        or sponsors showing their goods and/or services through the Service. In
        particular, HerbanApp is not a party to any transaction that you may
        enter into with a third-party. Any such activity, and any terms,
        conditions, warranties, or representations associated with such
        activity, is solely between you and the applicable third-party.
        HerbanApp shall have no liability, obligation or responsibility for any
        such correspondence, purchase, transaction, or promotion between you and
        any such third-party. You agree that it is your responsibility to take
        reasonable precautions in all actions and interactions with any third
        party you interact with through the Service.
      </p>
      <p>
        8.2 Third-Party Materials. The Site or Services might display, include,
        or make available third-party content (including data, information,
        articles applications or other products, services and/or materials) or
        contain links to third party websites, services, and advertisements for
        third parties such as Deals and dispensary Menus, including pricing,
        product names, and product descriptions each Menu (defined below)
        (collectively, “Third-Party Materials”). You acknowledge and agree that
        HerbanApp is not responsible for Third-Party Materials, including their
        accuracy, completeness, timeliness, validity, copyright compliance,
        legality, decency, quality or any other aspect thereof. HerbanApp does
        not assume and will not have any liability or responsibility to you or
        any other person or user for any Third-Party Materials. Third-Party
        Materials and links thereto are provided solely as a convenience to you
        and you access and use them entirely at your own risk. When you link to
        a Third-Party Material, the applicable third party’s terms and policies
        apply, including the third party’s privacy and data gathering practices.
        You should make whatever investigation you feel necessary or appropriate
        before proceeding with any transaction in connection with such
        Third-Party Material.
      </p>
      <p>
        8.3 Deals. The Site or Services might display, include, or make
        available coupons, promotional codes, giveaways, samples, and other
        offers from listed dispensaries (collectively, “Deals”). Deals
        constitute “Third Party Materials” under this Agreement. HerbanApp
        displays these Deals on the Site and Services as a form of advertisement
        for the listing dispensary (the “Offeror”) only. All Deals are offered
        directly by the applicable Offeror, and may be subject to additional
        terms, conditions, or restrictions of the Offeror or under applicable
        law, whether or not such additional terms, conditions, or restrictions
        are expressly included on the Site or Services. The Offeror, and not
        HerbanApp, is solely responsible for: (a) redemption of the Deal; (b)
        compliance of all aspects of the Deal with applicable law (including
        without limit, the advertisement, redemption, and terms, conditions and
        restrictions related thereto); (c) all goods and services it provides to
        you in connection with the Deal; and (d) all injuries, illnesses,
        damages, claims, liabilities and costs it may cause you to suffer,
        directly or indirectly, in full or in part, whether related to the use
        or redemption of a Deal or not.
      </p>
      <p>
        8.4 Other Users. Each user of the Site or Services is solely responsible
        for any and all of its User Content and Third-Party Materials. Because
        we do not control User Content and/or Third-Party Materials, you
        acknowledge and agree that we are not responsible for any User Content
        and/or Third-Party Materials, we make no guarantees regarding the
        accuracy, currency, suitability, or quality of any User Content and/or
        Third-Party Materials, and we assume no responsibility for any User
        Content and/or Third-Party Materials. Your interactions with other Site
        or Service users (including Dispensaries) are solely between you and
        such user. You agree that HerbanApp will not be responsible for any loss
        or damage incurred as the result of any such interactions. If there is a
        dispute between you and any Site or Service user, we are under no
        obligation to become involved.
      </p>
      <p>
        8.5 Release. In consideration of your use of the Site and Services, you
        hereby release and forever discharge HerbanApp (and our officers,
        employees, agents, successors, and assigns) from, and hereby waive and
        relinquish your rights with respect to, each and every past, present and
        future dispute, claim, controversy, demand, right, obligation,
        liability, action and cause of action of every kind and nature
        (including claims related to privacy, receipt of text messages, personal
        injuries, death, and property damage), known or unknown, that has arisen
        or arises directly or indirectly out of, or relates directly or
        indirectly to, any interactions with, or act or omission of, other Site
        or Service users, Third-Party Interactions, or Third-Party Materials. IF
        YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
        SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL
        RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR OR RELEASING PARTY
        DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
        EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER WOULD HAVE
        MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED
        PARTY.” If you are a non-California resident, you hereby agree that the
        release above applies to all claims, including claims you did not know
        existed at the time you signed this Agreement.
      </p>

      <p className={classes["section-title"]}>9. DISCLAIMERS</p>
      <p>
        THE SITE AND SERVICES ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE
        (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM, TO THE FULLEST EXTENT PERMITTED
        BY APPLICABLE LAW, ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
        EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET
        ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WITHOUT LIMITING THE
        FOREGOING, HERBANAPP (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE
        OR SERVICES: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON
        AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE
        ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE,
        LEGAL, OR SAFE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
        WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU
      </p>

      <p className={classes["section-title"]}>10. LIMITATION ON LIABILITY</p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
        HERBANAPP (AND OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR
        ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
        SPECIAL, OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT
        OR YOUR USE OF, OR INABILITY TO USE, THE SITE OR SERVICES, EVEN IF WE
        HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE
        OF, THE SITE AND SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU
        WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR
        LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE
        CONTRARY CONTAINED HEREIN, HERBANAPP’S (AND OUR SUPPLIERS’) LIABILITY TO
        YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY
        CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL
        TIMES BE LIMITED TO THE GREATER OF (A) FIFTY US DOLLARS ($50) OR (B)
        AMOUNTS YOU’VE PAID HERBANAPP IN THE PRIOR 12 MONTHS (IF ANY). THE
        EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY
        FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR
        EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS
        THAT VARY FROM JURISDICTION TO JURISDICTION.
      </p>
      <p className={classes["section-title"]}>11. TERM AND TERMINATION.</p>
      <p>
        Subject to this Section, this Agreement will remain in full force and
        effect while you use the Site or Services. We may (a) suspend your
        rights to use the Site and/or Services (including your HerbanApp
        Account) or (b) terminate this Agreement, at any time for any reason at
        our sole discretion, including for any use of the Site or Services in
        violation of this Agreement. Upon termination of this Agreement, your
        HerbanApp Account and right to access and use the Site and Services will
        terminate immediately. You understand that any termination of your
        HerbanApp Account involves deletion of your User Content associated
        therewith from our Site, Services, and live databases. HerbanApp will
        not have any liability whatsoever to you for any termination of this
        Agreement, including for termination of your HerbanApp Account or
        deletion of your User Content. Even after this Agreement is terminated,
        the following provisions of this Agreement will remain in effect:
        Sections 3.2-3.4, 4–14.
      </p>

      <p className={classes["section-title"]}>12. COPYRIGHT POLICY.</p>
      <p>
        HerbanApp respects the intellectual property of others and asks that
        users of our Site and Services do the same. In connection with our Site
        and Services, we have adopted and implemented a policy respecting
        copyright law that provides for the removal of any infringing materials
        and for the termination, in appropriate circumstances, of users of our
        Site and Services who are repeat infringers of intellectual property
        rights, including copyrights. If you believe that one of our users is,
        through the use of our Site and Services, unlawfully infringing the
        copyright(s) in a work, and wish to have the allegedly infringing
        material removed, the following information in the form of a written
        notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our
        designated Copyright Agent:
      </p>
      <p>12.1 Your physical or electronic signature;</p>
      <p>
        12.2 Identification of the copyrighted work(s) that you claim to have
        been infringed;
      </p>
      <p>
        12.3 Identification of the material on our services that you claim is
        infringing and that you request us to remove;
      </p>
      <p>12.4 Sufficient information to permit us to locate such material;</p>
      <p>12.5 Your address, telephone number, and e-mail address;</p>
      <p>
        12.6 A statement that you have a good faith belief that use of the
        objectionable material is not authorized by the copyright owner, its
        agent, or under the law; and
      </p>
      <p>
        12.7 A statement that the information in the notification is accurate,
        and under penalty of perjury, that you are either the owner of the
        copyright that has allegedly been infringed or that you are authorized
        to act on behalf of the copyright owner.
      </p>
      <p>
        Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation
        of material fact (falsities) in a written notification automatically
        subjects the complaining party to liability for any damages, costs and
        attorney’s fees incurred by us in connection with the written
        notification and allegation of copyright infringement.
      </p>
      <p>The Designated Copyright Agent for HerbanApp is:</p>
      <p>Copyright Agent</p>
      <p>Legal Department</p>
      <p>HerbanApp LLC.</p>
      <p>1216 Broadway 2nd FL</p>
      <p>New York, NY 10001</p>
      <p>Email: legal@herbanapp.com</p>

      <p className={classes["section-title"]}>
        13. ARBITRATION AGREEMENT AND JURY TRIAL WAIVER, CLASS ACTION WAIVER,
        AND FORUM SELECTION CLAUSE.
      </p>
      <p>
        Except for disputes brought in small claims court, all disputes between
        you and HerbanApp arising out of, relating to or in connection with the
        Site or Services shall be exclusively settled through binding
        arbitration pursuant to the then-current rules of the American
        Arbitration Association (“AAA”) for commercial arbitration and not in
        court. This agreement to arbitrate applies to any claim brought under
        the laws of any state or national government, including claims under the
        Telephone Consumer Protection Act of 1991, the Federal Trade Commission
        Act, the Consumer Product Safety Act, or any other federal, state or
        local law or ordinance. There is no judge or jury in arbitration and you
        are agreeing to waive your right to pursue claims in court to the
        greatest extent permitted by law. Arbitration procedures are simpler and
        more limited than rules applicable in court and review by a court is
        limited. YOU AND HERBANAPP AGREE THAT ANY SUCH ARBITRATION SHALL BE
        CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR
        REPRESENTATIVE ACTION. Notwithstanding any provision in this Agreement
        to the contrary, if the class and representative-action waiver in the
        prior sentence is deemed invalid or unenforceable, however, neither you
        nor we are entitled to arbitration, and any class or
        representative-action claims shall proceed in a court of competent
        jurisdiction in King County, Washington. This arbitration agreement is
        entered into and enforceable pursuant to the Federal Arbitration Act.
        The arbitrator’s award may be entered in any court of competent
        jurisdiction. Notwithstanding any provision in this Agreement to the
        contrary, we agree that if HerbanApp makes any future change to this
        dispute resolution provision, it will not apply to any individual
        claim(s) about which you had already provided notice to HerbanApp.
        Information on AAA and how to start arbitration can be found at
        www.adr.org or by calling 800-778-7879. If the arbitration in this
        Section provision is found unenforceable or to not apply for a given
        dispute, then the proceeding must be brought exclusively in a court of
        competent jurisdiction in King County, Washington. You hereby accept the
        exclusive jurisdiction of such court for this purpose. To the extent
        enforceability of this Agreement requires reference to any state law,
        the Parties agree such reference shall be to the laws of the State of
        Washington without regard to conflict of law provisions.
      </p>
      <p>
        13.1 Pre-Arbitration Dispute Resolution. Prior to resorting to formal
        dispute resolution procedures, you hereby agree to try to resolve
        disputes amicably and efficiently by emailing customer support at
        support@herban.app. Any communication to us regarding any dispute you
        have must be sent within ninety calendar days of the date of the event
        giving rise to the dispute. You agree that engaging in this
        pre-arbitration dispute resolution procedure is a prerequisite to
        initiating a complaint in arbitration or any other form. If
        pre-arbitration dispute resolution efforts prove unsuccessful, a party
        who intends to seek arbitration must first send to the other, by
        certified mail, a written Notice of Dispute (“Notice”) within ninety
        calendar days of the date on which the parties agree that the
        pre-arbitration dispute resolution has failed. The Notice to us should
        be sent to the address identified in Section 14.6 below. You hereby
        agree that failure to follow any of the steps outlined in this paragraph
        operates as a waiver of your right to pursue your claims in arbitration
        or any other forum.
      </p>
      <p>
        13.2 Confidentiality. All aspects of the arbitration proceeding, and any
        ruling, decision, or award by the arbitrator, will be strictly
        confidential for the benefit of all parties.
      </p>

      <p>
        13.3 Future Changes to Arbitration Agreement. Notwithstanding any
        provision in these Terms to the contrary, we agree that if it makes any
        future change to this Arbitration Agreement (other than a change to the
        Notice Address) while you are a user of the Service, you may reject any
        such change by sending us written notice within thirty (30) calendar
        days of the change to the Notice Address provided in Section below. By
        rejecting any future change, you are agreeing that you will individually
        arbitrate any dispute between us in accordance with the language of this
        Arbitration Agreement as of the date you first accepted these Terms (or
        accepted any subsequent changes to these Terms).
      </p>

      <p className={classes["section-title"]}>14. GENERAL</p>
      <p>
        14.1 No Support or Maintenance. You acknowledge and agree that HerbanApp
        will have no obligation to provide you with any support or maintenance
        in connection with the Site or Services.
      </p>
      <p>
        14.2 Changes to Terms of Use. We may amend these Terms at any time in
        our sole discretion. If we do so, we will post the modified Terms on the
        Service. The modifications will be effective immediately. You agree to
        review these Terms periodically so that you are aware of any
        modifications. Continued use of our Site or Services following notice of
        such changes shall indicate your acknowledgement of such changes and
        agreement to be bound by the terms and conditions of such changes.
      </p>
      <p>
        14.3 Copyright/Trademark Information. Copyright © 2016, HerbanApp LLC.
        All rights reserved. HerbanApp®; the HerbanApp logo; the HerbanApp
        green, purple and rusty maroon colors used in combination; the herbanapp
        green, purple, and rusty maroon colors used for hybrid, indica, and
        sativa cannabis strains; and the HerbanApp tile designs (collectively,
        the “Marks”) are trademarks of HerbanApp LLC. Apple®, App Store®, and
        iTunes® are registered trademarks of Apple, Inc. (“Apple”). Google
        PlayTM is a trademark of Google, LLC. You acknowledge and agree that You
        are not permitted to use HerbanApp’s Marks or any third-party marks
        displayed on our site without prior written consent from, respectively,
        HerbanApp LLC., Apple, or the owners of such third-party marks.
      </p>
      <p>
        14.4 Accessing and Downloading the Application from Apple. The following
        additional terms apply to any Mobile App accessed through or downloaded
        from the iTunes® or the App Store® (“App Store Sourced Mobile App”):
      </p>
      <p>
        (a) You acknowledge and agree that (i) this Agreement is concluded
        between you and HerbanApp only, and not Apple, and (ii) that HerbanApp,
        not Apple, is solely responsible for the App Store Sourced Mobile App
        and content thereof. The license granted to you in the Agreement to use
        the App Store Sourced Mobile App and Service is a non-transferable right
        to use the App Store Sourced Mobile App on any Apple Device that you own
        or control, and only as permitted by the Usage Rules set forth in the
        App Store Terms of Service.
      </p>
      <p>
        (b) You acknowledge that Apple has no obligation whatsoever to furnish
        any maintenance and support services with respect to the App Store
        Sourced Mobile App.
      </p>
      <p>
        (c) In the event of any failure of the App Store Sourced Mobile App to
        conform to any applicable warranty, you may notify Apple, and Apple will
        refund the purchase price for the App Store Sourced Mobile App to you.
        To the maximum extent permitted by applicable law, Apple will have no
        other warranty obligation whatsoever with respect to the App Store
        Sourced Mobile App. As between HerbanApp and Apple, any other claims,
        losses, liabilities, damages, costs or expenses attributable to any
        failure to conform to any warranty will be the sole responsibility of
        HerbanApp.
      </p>
      <p>
        (d) You and HerbanApp acknowledge that, as between HerbanApp and Apple,
        Apple is not responsible for addressing any claims you have or any
        claims of any third-party relating to the App Store Sourced Mobile App
        or your possession and use of the App Store Sourced Mobile App,
        including, but not limited to: (i) product liability claims; (ii) any
        claim that the App Store Sourced Mobile App fails to conform to any
        applicable legal or regulatory requirement; and (iii) claims arising
        under consumer protection or similar legislation.
      </p>
      <p>
        (e) You and HerbanApp acknowledge that, in the event of any third-party
        claim that the App Store Sourced Mobile App or your possession and use
        of that App Store Sourced Mobile App infringes that third-party’s
        intellectual property rights, as between HerbanApp and Apple, HerbanApp,
        not Apple, will be solely responsible for the investigation, defense,
        settlement and discharge of any such intellectual property infringement
        claim to the extent required by the Terms.
      </p>
      <p>
        (f) You and HerbanApp acknowledge and agree that Apple, and Apple’s
        subsidiaries, are third-party beneficiaries of the Agreement as related
        to your license of the App Store Sourced Mobile App, and that, upon your
        acceptance of the terms and conditions of the Agreement, Apple will have
        the right (and will be deemed to have accepted the right) to enforce the
        Agreement as related to your license of the App Store Sourced Mobile App
        against you as a third-party beneficiary thereof.
      </p>
      <p>
        (g) You represent and warrant that (i) you are not located in a country
        that is subject to a U.S. Government embargo, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        and (ii) you are not listed on any U.S. Government list of prohibited or
        restricted parties.
      </p>
      <p>
        (h) Without limiting any other terms of the Terms, you must comply with
        all applicable third-party terms of agreement when using the App Store
        Sourced Mobile App.
      </p>
      <p>
        14.5 Miscellaneous. This Agreement constitutes the entire agreement
        between you and us regarding the use of the Site and Services. Our
        failure to exercise or enforce any right or provision of this Agreement
        shall not operate as a waiver of such right or provision, nor shall any
        single or partial exercise by HerbanApp of any right or power hereunder
        preclude further exercise of that or any other right hereunder. The
        section titles in this Agreement are for convenience only and have no
        legal or contractual effect. The word “including” means “including
        without limitation.” If any provision of this Agreement is, for any
        reason, held to be invalid or unenforceable, the other provisions of
        this Agreement will be unimpaired and the invalid or unenforceable
        provision will be deemed modified so that it is valid and enforceable to
        the maximum extent permitted by law. Nothing contained herein shall be
        construed to establish an employment, partnership, or joint venture
        relationship between you and HerbanApp. This Agreement, and your rights
        and obligations herein, may not be assigned, subcontracted, delegated,
        or otherwise transferred by you without HerbanApp’s prior written
        consent, and any attempted assignment, subcontract, delegation, or
        transfer in violation of the foregoing will be null and void. The terms
        of this Agreement shall be binding upon assignees.
      </p>
      <p>14.6 HerbanApp Contact Information:</p>
      <p>HerbanApp LLC</p>
      <p>1216 Broadway 2nd FL</p>
      <p>New York, NY 10001</p>
      <p>Email: support@herban.app</p>
    </Modal>
  );
};

export default TermsOfService;
