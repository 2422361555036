import axios from "axios";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../firebase";

const sendMail = async ({
  email,
  isGeneralTab,
  message,
  name,
  subject,
}: {
  email: string;
  name: string;
  subject: string;
  message: string;
  isGeneralTab: boolean;
}) => {
  const text = isGeneralTab
    ? `Full name: ${name}\nEmail: ${email}\nMessage: ${message}`
    : `Dispensary name: ${name}\nEmail: ${email}\nMessage: ${message}`;

  try {
    await axios.post("https://site-mailer.herban.app/send-email", {
      email,
      name,
      subject: `HB Form - ${subject}`,
      body: text,
    });
    console.log("Email sent");
    logEvent(firebaseAnalytics, "email_sent", {
      email,
      name,
      subject,
      body: text,
    });
    return true;
  } catch (error) {
    // console.log(error);
    return false;
  }
};

export default sendMail;
