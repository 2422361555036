import React from "react";
import classes from "./index.module.scss";
import R from "../../res";
import TextField from "./TextField";
import validateEmail from "../../util/validateEmail";
import Loader from "../../components/Loader";
import sendMail from "../../util/sendMail";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "../../firebase";

const ContactPage = () => {
  const [isGeneralTab, setIsGeneralTab] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    logEvent(firebaseAnalytics, "page_view", {
      page_path: "/contact",
    });
  }, []);

  const canProceed = React.useMemo(() => {
    if (
      email.trim().length === 0 ||
      message.trim().length === 0 ||
      subject.trim().length === 0 ||
      (!isGeneralTab && name.trim().length === 0)
    )
      return false;
    return validateEmail(email);
  }, [email, name, subject, message, isGeneralTab]);

  const sendForm = React.useCallback(async () => {
    if (!canProceed || isLoading) return;

    setIsLoading(true);
    await sendMail({
      email: email.trim(),
      name: name.trim(),
      subject: subject.trim(),
      message: message.trim(),
      isGeneralTab,
    });

    setEmail("");
    setName("");
    setMessage("");
    setSubject("");
    setIsLoading(false);
  }, [canProceed, isLoading, email, name, subject, message]);

  return (
    <div className={classes["container"]}>
      <div className={`${classes["row"]} ${classes["logo-section"]}`}>
        <img
          src={R.images.default.ilLogo}
          className={classes["logo"]}
          alt="Logo"
        />
        <p className={classes["logo-text"]}>HerbanApp</p>
      </div>
      <p className={classes["contact"]}>contact</p>
      <p className={classes["touch"]}>Get in Touch</p>
      <p className={classes["description"]}>
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.
      </p>
      <div className={classes["tabs"]}>
        <button
          className={classes["tab"]}
          onClick={() => setIsGeneralTab(true)}
          style={{
            opacity: !isGeneralTab ? 0.5 : 1,
            borderBottomColor: !isGeneralTab ? "transparent" : "#5eab6c",
          }}
        >
          <div className={classes["logo-container"]}>
            <img
              src={R.images.default.ilLogo}
              className={classes["logo"]}
              alt="Logo"
            />
          </div>
          <div className={classes["content"]}>
            <p className={classes["label"]}>General Queries</p>
            <p className={classes["email"]}>support@herban.app</p>
          </div>
        </button>
        <button
          className={classes["tab"]}
          onClick={() => setIsGeneralTab(false)}
          style={{
            opacity: isGeneralTab ? 0.5 : 1,
            borderBottomColor: isGeneralTab ? "transparent" : "#5eab6c",
          }}
        >
          <div className={classes["logo-container"]}>
            <img
              src={R.images.default.icMerchant}
              className={classes["logo"]}
              alt="Logo"
            />
          </div>
          <div className={classes["content"]}>
            <p className={classes["label"]}>Merchant Quieries</p>
            <p className={classes["email"]}>merchant@herban.app</p>
          </div>
        </button>
      </div>
      <div className={classes["inputs"]}>
        <div className={classes["input-row"]}>
          <TextField
            label="Email"
            required
            value={email}
            onChange={setEmail}
            placeholder="Type your email address"
          />
          <TextField
            label={isGeneralTab ? "Name" : "Dispensary Name"}
            value={name}
            required={!isGeneralTab}
            onChange={setName}
            placeholder={
              isGeneralTab ? "Type your full name" : "Type your dispensary name"
            }
          />
        </div>
        <TextField
          label="Subject"
          required
          value={subject}
          onChange={setSubject}
          placeholder="Type your subject"
        />
        <TextField
          label="Message"
          required
          textarea
          value={message}
          onChange={setMessage}
          placeholder="Type your message"
        />
      </div>
      <p className={classes["info"]}>
        We aim to respond within 48 hours. However, response times may vary due
        to high inquiry volumes or unforeseen circumstances. Your patience is
        appreciated. Thank you.
      </p>
      <button
        className={classes["button"]}
        disabled={!canProceed || isLoading}
        onClick={sendForm}
      >
        {isLoading ? <Loader color="#fff" /> : <p>Send Message</p>}
      </button>
    </div>
  );
};

export default ContactPage;
