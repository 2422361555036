import React from "react";
import Modal from "../Modal";
import classes from "./index.module.scss";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const PrivacyPolicy = (props: Props) => {
  return (
    <Modal isVisible={props.isVisible} onClose={props.onClose}>
      <p className={classes["label"]}>legal pages</p>
      <p className={classes["title"]}>Privacy Policy</p>

      <p>
        Welcome to HerbanApp.com, Herban.app, and related sub-domains (together,
        the “Site”), mobile and/or software applications, provided by HerbanApp
        LLC., and its affiliates, subsidiaries, parent company and other related
        companies (“Us”, “We”, or HerbanApp). This HerbanApp Privacy Notice
        (“Notice”) describes the information we collect, use, and disclose about
        you when you use the Site, mobile applications, and related services
        (together, the “Services”) and how we use, process, and disclose that
        information. We may supplement this Notice with other notices. This
        Notice applies to Services that display or reference this Notice, but it
        does not apply to any services that display or reference a different
        privacy statement.
      </p>

      <p className={classes["section-title"]}>
        Information We Collect from You Through Your Use of the Services
      </p>
      <p>
        The information we collect depends on how you interact with us, the
        Services you use, and the choices you make.
      </p>
      <p>
        We collect information about you from different sources and in various
        ways when you use our Services, including information you provide
        directly, information collected automatically, third-party data sources,
        and information we infer or generate from other data.
      </p>
      <p>
        Provided By You Directly. You may provide us with additional information
        by filling in forms on the Services or by corresponding with us or by
        using our Services by phone, text, email, live chat, or otherwise. If
        you create an account, we may collect the following types of information
        from you:
      </p>

      <p>
        ● Contact information. We collect contact information such as your name,
        username, email address, and phone number;
      </p>
      <p>
        ● Demographics data. When you register or at other times, we request
        that you provide demographics information such as your gender and
        location;
      </p>
      <p>
        ● Content and files. We collect photos, documents, files, and any other
        information that you provide us, such as reviews, content, and bio
        information. If you send us email messages or other communications, we
        will collect and retain those communications; and
      </p>
      <p>
        ● Reservations and transactions. If you initiate a reservation or order
        through our Services, such as a pickup reservation, we collect
        information necessary to complete your reservation or order, such as
        your contact information, order information, government-issued ID (if
        necessary), and depending on what type of reservation or order you
        place, a valid delivery address. In some instances, you may also submit
        data that may be considered personal medical information, such as
        medical marijuana patient identification for certain orders placed
        through our Services. We use this information to facilitate your order
        (and future orders), from receiving the order to sending the information
        to the retailer (or the retailer’s authorized service provider) you
        intend to have fulfill your order.
      </p>

      <p>
        Information Collected Automatically. When you use the Services, se
        automatically collect information about you, for example:
      </p>
      <p>
        ● Usage data. We automatically log your activity on our Services,
        including pages you have viewed and actions you take on the Services and
        the URL of the website from which you came to our sites.
      </p>
      <p>
        ● Identifiers and device information. When you visit our Services, our
        servers automatically collect certain technical information about your
        device including your Internet protocol address and information about
        your device, including device identifiers (such as MAC address), your
        browser type, language and identifying information, your operating
        system and application version, device types, device model and
        manufacturer, and your device operating system type and version. As
        further described in the Cookies and Similar Technologies section below,
        our Services store and retrieve cookie identifiers, mobile IDs, and
        other data.
      </p>
      <p>
        ● Geolocation data. Depending on your device and app settings, we
        collect geolocation data when you use our apps or online services.
      </p>
      <p>
        Cookies and Similar Technologies. We also use cookies, Web beacons,
        mobile analytics and advertising IDs, URL information, and other similar
        technologies to operate our Services and to gather data, including other
        identifiers, device information, and usage data. Cookies are small
        pieces of information that a website sends to your computer’s hard drive
        while you are viewing a website. We may use both session cookies (which
        expire once you close your web browser) and persistent cookies (which
        usually stay on your computer until you delete them) to provide you with
        a more personal and interactive experience on our Site. The text in a
        cookie often consists of a string of numbers and letters that uniquely
        identifies your device, but it can contain other information as well. We
        use cookies on our websites to store your preferences and settings,
        enable you to sign-in, provide interest-based advertising, combat fraud,
        analyze how our Services perform, and fulfill other legitimate purposes.
      </p>
      <p>
        Our web pages may also contain electronic images known as web beacons
        (also called single-pixel gifs) that we use to help deliver cookies on
        our websites, count users who have visited those websites, and gather
        usage and performance data. We also include web beacons in our
        promotional email messages or newsletters to determine whether you open
        and act on them.
      </p>
      <p>
        Mobile analytics and advertising IDs are generated by operating systems
        for mobile devices (iOS and Android) and can be accessed and used by
        apps in much the same way that websites access and use cookies. Our apps
        contain software that enables our third-party analytics and advertising
        partners to access the mobile IDs.
      </p>
      <p>
        We, together with our third-party analytics service providers and
        advertising partners, use these technologies in our Services to collect
        personal information (such as the cookies stored on your browser, the
        advertising identifier on your mobile device, or the IP address of your
        device) when you visit our Services. We and our partners may also use
        these technologies to collect personal information about your online
        activities over time and across different Web sites. This information is
        used to store your preferences and settings, enable you to sign-in,
        analyze how our Services perform, track your interaction with the
        Services, develop inferences, deliver and tailor interest-based
        advertising, combat fraud, and fulfill other legitimate purposes. We
        and/or our partners also share the information we collect or infer with
        third parties for these purposes.
      </p>
      <p>
        The third-party analytics and advertising providers we use on our
        websites include:
      </p>
      <div className={classes["table"]}>
        <p>Company/Service</p>
        <p>Purpose (s)</p>
        <p>Privacy Notices</p>
        <p>Manage Settings (opt-out)</p>
      </div>
      <div className={classes["table"]}>
        <p>DoubleClick (Google)</p>
        <p>Advertising, analytics</p>
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/privacy
        </a>
        <p>See association links below.</p>
      </div>
      <div className={classes["table"]}>
        <p>Google Analytics</p>
        <p>Analytics</p>
        <a
          href="www.google.com/policies/privacy/partners"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.google.com/policies/privacy/partners
        </a>
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
      </div>

      <div className={classes["table"]}>
        <p>Google Tag Manager</p>
        <p></p>
        <a
          href="www.google.com/policies/privacy/partners"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.google.com/policies/privacy/partners
        </a>
        <a
          href="https://policies.google.com/technologies/partner-sites"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://policies.google.com/technologies/partner-sites
        </a>
      </div>
      {/* 
      <div className={classes["table"]}>
        <p>Facebook Audiences</p>
        <p>Advertising</p>
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/privacy/explanation
        </a>
        <a
          href="https://www.facebook.com/settings?tab=ads"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/settings?tab=ads
        </a>
      </div>

      <div className={classes["table"]}>
        <p>Facebook Connect</p>
        <p></p>
        <a
          href="https://www.facebook.com/privacy/explanation"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.facebook.com/privacy/explanation
        </a>
        <p>See association links below.</p>
      </div>

      <div className={classes["table"]}>
        <p>Chartbeat</p>
        <p>Analytics</p>
        <a
          href="https://chartbeat.com/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://chartbeat.com/privacy/
        </a>
        <a
          href="https://static.chartbeat.com/opt-out.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://static.chartbeat.com/opt-out.html
        </a>
      </div>

      <div className={classes["table"]}>
        <p>StackAdapt</p>
        <p>Advertising</p>
        <a
          href="https://www.stackadapt.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.stackadapt.com/privacy
        </a>
        <a
          href="https://www.stackadapt.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          See StackAdapt’s Privacy Notice
        </a>
      </div>

      <div className={classes["table"]}>
        <p>MiQ</p>
        <p>Advertising</p>
        <a
          href="https://www.wearemiq.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.wearemiq.com/privacy-policy/
        </a>
        <a
          href="https://www.wearemiq.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          See MiQ’s Privacy Notice
        </a>
      </div> */}

      <p>
        We permit advertisements to be delivered to you by third-party Internet
        advertising companies (also called ad networks or network advertisers).
        These companies may use cookies, Web beacons, platform device
        identifiers, software agents, and other technologies to collect
        non-personally identifiable information about your visits over time on
        our Service and across other websites to deliver advertisements to you
        targeted to your interests, measure their effectiveness and personalize
        advertising content, and to understand the usage and visitation of the
        Services and the other applications and websites tracked by these
        advertising companies. We do not have access to or control over cookies,
        Web beacons, platform device identifiers, software agents, or other
        technologies that they may use. We are not responsible for the privacy
        practices of third party advertisers. You should check the privacy
        policy of the third-party advertiser to determine how it handles
        information it separately collects from you. In addition, the{" "}
        <a
          href="http://www.networkadvertising.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Network Advertising Initiative
        </a>{" "}
        offers information about some of the Internet advertising companies we
        may use.
      </p>
      <p>
        To learn about the privacy practices of these third parties and how to
        opt-out from their use of cookie data for targeted advertising purposes,
        click on the links above.
      </p>
      <p>
        Many of these companies are also members of associations, which provide
        a simple way to opt out of analytics and ad targeting, which you can
        access at:
      </p>

      <p>
        ● United States: NAI and DAA{" "}
        <a
          href="http://www.networkadvertising.org/managing/opt_out.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.networkadvertising.org/managing/opt_out.aspx)
        </a>
        {" and DAA "}
        <a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.aboutads.info/choices/)
        </a>
      </p>
      <p>
        ● Canada: Digital Advertising Alliance of Canada{" "}
        <a
          href="https://youradchoices.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (https://youradchoices.ca/)
        </a>
      </p>
      <p>
        ● Europe: European Digital Advertising Alliance{" "}
        <a
          href="http://www.youronlinechoices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.youronlinechoices.com/)
        </a>
      </p>

      <p className={classes["section-title"]}>
        California “Do Not Sell My Info”
      </p>
      <p>
        The California Consumer Protection Act (“CCPA”) requires us to disclose
        categories of personal data sold to third parties and how to opt-out of
        future sales. The CCPA defines personal information to include online
        identifiers, including IP address, cookies IDs, and mobile IDs. The law
        also defines a “sale” to include simply making data available to third
        parties in some cases. We let advertising and analytics providers
        collect IP addresses, cookie IDs, mobile IDs through our sites and apps
        when you use our online services, but do not “sell” any other types of
        personal information.
      </p>
      <p>
        If you do not wish for us or our partners to “sell” your personal data
        to third parties for advertising purposes, you can make your Do Not Sell
        Request by emailing privacy@herban.app.
      </p>
      <p>
        Note that although we will not “sell” your personal data after we
        receive your email, we will continue to share some personal information
        with our partners (acting as our service providers) to help us perform
        advertising-related functions such as, but not limited to, measuring the
        effectiveness of our ads, managing how many times you may see an ad,
        reporting on the performance of our ads, ensuring services are working
        correctly and securely, providing aggregate statistics and analytics,
        improving when and where you may see ads, and/or reducing ad fraud.
        Further, emailing us will not necessarily opt you out of the use of
        previously sold personal information or stop all interest-based
        advertising. If you access this site (or app) from other devices or
        browsers, visit the link below from those devices or browsers to ensure
        your choice applies to the data collected when you use those devices or
        browsers.
      </p>

      <p>
        Other information related to your right to opt-out from sales of
        personal data is contained in the California Privacy Rights section of
        this Notice.
      </p>

      <p>
        <span>Cookie controls.</span> Most web browsers are set to accept
        cookies by default. If you prefer, you can go to your browser settings
        to learn how to delete or reject cookies. If you choose to delete or
        reject cookies, this could affect certain features or services of our
        website. If you choose to delete cookies, settings and preferences
        controlled by those cookies, including advertising preferences, may be
        deleted and may need to be recreated.
      </p>
      <p>
        <span>Mobile advertising ID controls.</span> iOS and Android operating
        systems provide options to limit tracking and/or reset the advertising
        IDs.
      </p>
      <p>
        <span>Email web beacons.</span> Most email clients have settings which
        allow you prevent the automatic downloading of images, which will
        disable web beacons in the email messages you read.
      </p>

      <p>
        <span>Do Not Track.</span> Some browsers have incorporated “Do Not
        Track” (DNT) features that can send a signal to the websites you visit
        indicating you do not wish to be tracked. Because there is not a common
        understanding of how to interpret the DNT signal, our websites do not
        currently respond to browser DNT signals. Instead, you can use the range
        of other tools to control data collection and use, including the cookie
        controls and advertising controls described above.
      </p>

      <p>
        <span>Information obtained from third-party sources.</span> We also
        obtain information from third parties. We protect information obtained
        from third parties according to the practices described in this Notice,
        plus any additional restrictions imposed by the source of the
        information. These third-party sources include, for example:
      </p>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>Third Party Sources</p>
        <p>Categories of Personal Data</p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Third party partners. Third party applications and services, including
          social networks you choose to connect with or interact with through
          our services. For example, if you create an account using, or
          otherwise connect your account to, a social networking services
          account (e.g. Twitter or Facebook), we may also collect information
          provided to us by such social networking service.
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Co-Branding/Joint Partners. Partners with which we offer co-branded
          services or engage in joint marketing activities
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Service providers. Third parties that collect or provide data in
          connection with work they do on our behalf, for example companies that
          determine your device’s location based on its IP address.
        </p>
        <p>
          Contact information, demographic data, payment information, content
          and files, and device information, geolocation data, usage data,
          inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Publicly available sources. Public sources of information such as open
          government databases.
        </p>
        <p>
          Contact information, demographic data, content and files, geolocation
          data.
        </p>
      </div>

      <p>
        <span>Information created or generated.</span> We infer new information
        from other data we collect, including using automated means to generate
        information about your likely preferences or other characteristics
        (“inferences”). For example, we infer your city, state, and country
        location based on your IP address.
      </p>

      <p>
        When you are asked to provide information, you may decline. And you may
        use app or device controls to prevent certain types of automatic data
        collection. But if you choose not to provide or allow information that
        is necessary for certain products or features, those products or
        features may not be available or function correctly.
      </p>

      <p className={classes["section-title"]}>
        Your Sharing of Your Information
      </p>
      <p>
        The HerbanApp Services may allow you to connect and share your actions,
        comments, content, profile, and information publicly or with other
        people. Depending on what domain your account is on and what type of
        account you have created, you may be able to control some of the
        categories of personal information shared via your profile from the
        account settings menu on the Site. Please be mindful of your own privacy
        needs as you choose who to connect with and what to share and make
        public. We cannot control the privacy or security of information you
        choose to make public or share with others.
      </p>
      <p>
        The Services may also provide you with the option to share certain
        information from your HerbanApp account with social networking services
        like Facebook, Google, or Twitter. We are not responsible for the use or
        re-sharing by others of any of your information once it is made public.
        If you do not want your information to be made public, you should not
        use the HerbanApp Services in this manner and/or you should adjust your
        privacy settings on the applicable social networking service
        accordingly. We are not responsible for and we do not control these
        social networking services privacy practices. Please review the
        applicable privacy policy for information about how they use your
        information.
      </p>

      <p className={classes["section-title"]}>
        How HerbanApp Uses Your Information
      </p>
      <p>
        We use the information we collect for purposes described in this Notice
        or otherwise disclosed to you. For example, HerbanApp generally uses
        your information to:
      </p>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>Purposes of Use</p>
        <p>Categories of Personal Data</p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Product and Service Delivery. To provide and deliver our Services,
          including creating accounts on, securing, troubleshooting, improving,
          and personalizing those Services.e
        </p>
        <p>
          Contact information, demographic data, payment information, content
          and files, identifiers and device information, geolocation data, usage
          data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Business Operations. To operate our business, such as billing,
          accounting, improving our internal operations, securing our systems,
          and detecting and protecting against fraudulent or illegal activity.
        </p>
        <p>
          Contact information, demographic data, payment information, content
          and files, identifiers and device information, geolocation data, usage
          data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Product and Service Improvement, Development, and Research. To improve
          our Services, develop new products or features, and conduct research.e
        </p>
        <p>
          Contact information, demographic data, payment information, content
          and files, identifiers and device information, geolocation data, usage
          data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Personalization. To understand you and your preferences to enhance
          your experience and enjoyment using our Services.
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Customer Support. To provide customer support and respond to your
          questions.
        </p>
        <p>
          Contact information, demographic data, payment information, content
          and files, identifiers and device information, geolocation data, usage
          data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Communications. To send you information about your use of the
          Services, including confirmations, invoices, technical notices,
          updates, security alerts, and support and administrative messages.
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data.
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Marketing. To communicate with you about new products, offers,
          promotions, rewards, contests, upcoming events, and other information
          about our products and those of our selected partners (see the
          Information Choices section of this Notice for how to change your
          preferences for promotional communications)
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data, inferences
        </p>
      </div>

      <div className={`${classes["table"]} ${classes["two-columns"]}`}>
        <p>
          Advertising. To display advertising to you (see the Cookies and
          Similar Technologies section of this Notice for information about
          personalized advertising and your advertising choices).
        </p>
        <p>
          Contact information, demographic data, content and files, identifiers
          and device information, geolocation data, usage data, inferences
        </p>
      </div>

      <p>
        We may also use your information to verify your geographic location. We
        may use your geographic location data to personalize our Service, to
        recommend content, determine whether the information you have requested
        is available in your location, or to determine whether Service offerings
        are available in your location. If Information is aggregated or
        de-identified so it is no longer reasonably associated with an
        identified or identifiable natural person, HerbanApp may use it for any
        business purpose.
      </p>

      <p className={classes["section-title"]}>
        How HerbanApp Discloses Your Information
      </p>
      <p>We may share your information as follows:</p>
      <p>
        ● We may share your personal information with your consent or at your
        direction or as otherwise as necessary to complete your transactions or
        provide the Services you have requested or authorized. By placing a
        reservation or order with a dispensary or retailer through our Services,
        you consent to HerbanApp sharing the information related to your
        reservation or order with the dispensary or retailer, to the extent
        necessary to fulfill your reservation or order.
      </p>
      <p>
        ● With our subsidiaries and affiliates, who may share common data
        systems and process information as needed to provide the Services and
        operate our business.
      </p>
      <p>
        ● We may also share your personal information with others who perform
        services on our behalf (for example, with Twilio if you make a call or
        receive/respond to a text using the Service).
      </p>
      <p>
        ● We may share your information with third-party service providers to
        fulfill your requests and to perform functions on our behalf. Examples
        include product reservation requests, fulfilling orders, and delivering
        orders. We only share information necessary for these third-party
        service providers to perform their functions, and any information shared
        may not be used for any other purpose.
      </p>
      <p>
        ● To provide us with advertising and marketing services, we may share
        your information about you, such as order history, demographic data, and
        interests with certain third-party partners to target and serve you ads
        for our Services on other sites. These third parties may also use this
        collected information to draw inferences about you for their own
        purposes or collect information about your online activities over time
        and across different websites and services (in addition to our Services)
        to help predict your preferences and to display (review the Cookies and
        Similar Technologies section above) ads to you that are more likely to
        be of interest to you. Note that this Privacy Policy covers only our use
        of data and does not include use of data by such third parties. If you
        wish to opt out of interest-based advertising, please visit{" "}
        <a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          http://www.aboutads.info/choices/
        </a>{" "}
        For California residents, please see the California Privacy Rights
        section of this Privacy Policy to review your rights to opt out.
      </p>
      <p>
        ● We may disclose your information if we believe we are required to do
        so by law, or to comply with a court order, judicial or other government
        subpoena, or warrant.
      </p>
      <p>
        ● We also may disclose your information if we believe doing so is
        appropriate or necessary to prevent any liability, or fraudulent,
        abusive, or unlawful uses or to protect HerbanApp and our Services; or
        any rights, property, or personal safety of HerbanApp or others,
        including as we believe is necessary to enforce our agreements, terms,
        and policies.
      </p>
      <p>
        ● In the event that HerbanApp is or may be acquired by or merged with
        another company or involved in any other business deal (or negotiation
        of a business deal), including a financing, transfer, or divestiture,
        involving sale or transfer of all or part of our business or assets, we
        may transfer or assign your information as part of or in connection with
        the transaction. Finally, in the event of insolvency, dissolution,
        bankruptcy, or receivership, information may be transferred as a
        business asset.
      </p>
      <p>
        Third party analytics and advertising companies also collect personal
        information through our Services including, account information,
        marketing and communications data, demographic data, content and files,
        geolocation data, usage data, and inferences associated with identifiers
        and device information (such as cookie IDs, device IDs, and IP address)
        as described in the Cookies and Similar Technologies section of this
        Notice.
      </p>
      <p>
        We may also share aggregated and/or anonymized data with our partners,
        advertisers, and other third parties as authorized by law for purposes
        including use for their own advertising and marketing activities.
      </p>
      <p>
        Please note that some of our products include references or links to
        products provided by third parties whose privacy practices differ from
        ours. If you provide information to any of those third parties, or
        consent to our sharing information with them, that information is
        governed by their privacy statements.
      </p>

      <p className={classes["section-title"]}>Data Security</p>
      <p>
        HerbanApp uses various safeguards designed to protect personal
        information submitted to us, including, where appropriate, encryption of
        data stored and encrypting information in transit. Where HerbanApp uses
        third party providers to store and transmit personal information,
        HerbanApp utilizes the security tools offered by these providers that
        are designed to protect personal information. In addition to use of
        certain security tools made available by third party providers,
        HerbanApp takes reasonable and appropriate steps to help protect your
        Information against loss, misuse, and unauthorized access, or
        disclosure. No company can fully prevent security risks, however. While
        we strive to protect your personal information, we cannot guarantee its
        absolute security. To help protect yourself and your information, choose
        a unique password for our Services and do not use a password on our
        Services that you would use on any other website or online service.
      </p>

      <p className={classes["section-title"]}>Location of Personal Data</p>
      <p>
        The information we collect may be stored and processed in your country
        or region, or in any other country where we or our affiliates,
        subsidiaries, or service providers maintain facilities. Currently, we
        primarily use data centers in the United States where our servers are
        located. We have chosen this location to operate efficiently and improve
        performance. That information may then be transferred within the United
        States or back out of the United States to other countries outside of
        your country of residence, depending on the type of information and how
        it is stored by us. These countries (including the United States) may
        not necessarily have the data protection laws as comprehensive or
        protective as those in your country of residence; however we take steps
        designed to ensure that the information we collect under this Notice is
        processed according to the provisions of this Notice and applicable law
        wherever the information is located.
      </p>
      <p>
        Accordingly, we transfer personal data from the European Economic Area
        and Switzerland to other countries, some of which have not been
        determined by the European Commission to have an adequate level of data
        protection. When we do so, we use a variety of legal mechanisms,
        including contracts, to help ensure your rights and protections. To
        learn more about the European Commission’s decisions on the adequacy of
        personal data protections, please visit:{" "}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en."
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en.
        </a>
      </p>

      <p className={classes["section-title"]}>Retention of Information</p>
      <p>
        We retain information for as long as necessary to provide the Services
        you have requested, comply with our legal obligations, resolve disputes,
        enforce our agreements, and other legitimate and lawful business
        purposes. Because these needs can vary for different data types in the
        context of different products, actual retention periods can vary
        significantly based on criteria such as user expectations or consent,
        the sensitivity of the data, the availability of automated controls that
        enable users to delete data, and our legal or contractual obligations.
      </p>

      <p className={classes["section-title"]}>Information Choices</p>
      <p>
        <span>Access, correction, and deletion.</span> If you wish to request
        access to, or correction or deletion of, information about you that we
        hold, you may email your request to privacy@herban.app.
      </p>
      <p>
        To the extent permitted by applicable law, we reserve the right to
        charge a fee or decline requests that are unreasonable or excessive,
        where providing the information would be prohibited by law or could
        adversely affect the privacy or other rights of another person, or where
        we are unable to authenticate you as the person to whom the information
        relates.
      </p>
      <p>
        <span>Communication Preferences.</span> You may opt out of receiving
        marketing/promotional emails from HerbanApp by following the
        instructions in those emails or by reviewing the settings in your
        account, depending on what type of account you have. You may opt out of
        receiving marketing/promotional text messages from HerbanApp at any time
        by replying STOP to a marketing/promotional text message. If you opt out
        of receiving marketing/promotional emails and/or text messages, we may
        still send you non-promotional emails, such as emails about your
        accounts or our ongoing business relations
      </p>
      <p>
        Choices for Cookies and Similar Technologies. See the Cookies and
        Similar Technologies section for choices about cookies and other
        analytics and advertising controls.
      </p>
      <p>
        Many third-party tracking companies are also members of associations,
        which provide a simple way to opt out of analytics and ad targeting,
        which you can access at:
      </p>

      <p>
        ● United States: NAI and DAA{" "}
        <a
          href="http://www.networkadvertising.org/managing/opt_out.aspx"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.networkadvertising.org/managing/opt_out.aspx)
        </a>
        {" and DAA "}
        <a
          href="http://www.aboutads.info/choices/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.aboutads.info/choices/)
        </a>
      </p>
      <p>
        ● Canada: Digital Advertising Alliance of Canada{" "}
        <a
          href="https://youradchoices.ca/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (https://youradchoices.ca/)
        </a>
      </p>
      <p>
        ● Europe: European Digital Advertising Alliance{" "}
        <a
          href="http://www.youronlinechoices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          (http://www.youronlinechoices.com/)
        </a>
      </p>

      <p>
        <span>Mobile advertising ID controls.</span> Apple and Android mobile
        devices each generate an advertising identifier that can be accessed by
        apps and used by advertisers in much the same way that cookies are used
        on websites. Each operating system provides options to limit tracking
        and/or reset the advertising ID.
      </p>
      <p>
        You may decline to share certain data with HerbanApp, in which case we
        may not be able to provide you with some of the features and
        functionality of the Site or Service. You may have the right to know
        what personal information HerbanApp has about you and to correct any
        inaccuracies. Please direct any such requests by email to
        privacy@herban.app. or by one of the other means listed at the end of
        this notice.
      </p>
      <p>
        <span>Right to Opt-out. </span> You may opt-out of future “sales” of
        personal information. To do so, please visit our “Do Not Sell My
        Personal Information” page in the Cookies and Similar Technologies
        section of this Notice.
      </p>

      <p className={classes["section-title"]}>Your EU Privacy Rights</p>
      <p>
        If the processing of personal data about you is subject to European
        Union data protection law, you have certain rights with respect to that
        data:
      </p>
      <p>
        ● You can request access to, and rectification or erasure of, personal
        data;
      </p>
      <p>
        ● If any automated processing of personal data is based on your consent
        or a contract with you, you have a right to transfer or receive a copy
        of the personal data in a usable and portable format;
      </p>
      <p>
        ● If the processing of personal data is based on your consent, you can
        withdraw consent at any time for future processing;
      </p>
      <p>
        ● You can to object to, or obtain a restriction of, the processing of
        personal data under certain circumstances; and
      </p>
      <p>
        ● For residents of France, you can send us specific instructions
        regarding the use of your data after your death.
      </p>
      <p>
        To make such request, contact us at the contact information set forth
        below. When we are processing data on behalf of another party that is
        the “data controller,” you should direct your request to that party. You
        also have the right to lodge a complaint with a supervisory authority,
        but we encourage you to first contact us with any questions or concerns.
      </p>
      <p>
        We rely on different lawful bases for collecting and processing personal
        data about you, for example, with your consent and/or as necessary to
        provide the Services you use, operate our business, meet our contractual
        and legal obligations, protect the security of our systems and our
        customers, or fulfill other legitimate interests.
      </p>

      <p className={classes["section-title"]}>California Privacy Rights</p>
      <p>
        If you are a California resident and the processing of personal
        information about you is subject to the California Co
      </p>
      <p>
        <span>Right to Know.</span> You have a right to request that companies
        provide you with the following information:
      </p>
      <p>
        ● The categories and specific pieces of personal information we have
        collected about you.
      </p>
      <p>
        ● The categories of sources from which we collect personal information.
      </p>
      <p>
        ● The categories of third parties with which we share personal
        information.
      </p>
      <p>
        ● The categories of personal information we have disclosed about you for
        a business purpose. Note that the CCPA defines “business purpose”
        broadly; and because we use service providers for a number of business
        purposes that require access to our systems that hold personal
        information (such as supplying cloud data storage, maintaining the
        security of our systems, and providing customer support), in the past 12
        months we have disclosed for a business purpose data from each of the
        categories of personal information we maintain.
      </p>
      <p>
        ● The categories of personal information we have sold about you (if
        any), for each category of third parties to which the personal
        information was sold. Note that the CCPA defines “sell” and “personal
        information” very broadly, and some of our data sharing described in
        this Notice may be considered a “sale” under that definition. In
        particular, we let advertising and analytics providers collect IP
        addresses, cookie IDs, and mobile IDs through our sites and apps when
        you use our online services, but do not “sell” any other types of
        personal information. We provide our “Do Not Sell My Info” disclosures
        in the Cookies and Similar Technologies section of this Notice.
      </p>
      <p>
        You may make such a “request to know” by contacting us at
        privacy@herban.app.. Note that we have provided much of this information
        in this Notice.
      </p>
      <p>
        <span>Right to Request Deletion.</span> You also have a right to request
        that we delete personal information under certain circumstances, subject
        to a number of exceptions. To make a request to delete, contact us at
        privacy@herban.app
      </p>
      <p>
        <span>Right to Opt-Out.</span> You have a right to opt-out from future
        “sales” of personal information. To do so, please visit our “Do Not Sell
        My Info” page in the Cookies and Similar Technologies section of this
        Notice.
      </p>
      <p>
        You may designate, in writing or through a power of attorney, an
        authorized agent to make requests on your behalf to exercise your rights
        under the CCPA. Before accepting such a request from an agent, we will
        require the agent to provide proof you have authorized it to act on your
        behalf, and we may need you to verify your identity directly with us.
        Further, to provide or delete specific pieces of personal information we
        will need to verify your identity to the degree of certainty required by
        law. Finally, you have a right to not be discriminated against for
        exercising rights set out in the CCPA.
      </p>
      <p>
        Additionally, under California Civil Code section 1798.83, also known as
        the “Shine the Light” law, California residents who have provided
        personal information to a business with which the individual has
        established a business relationship for personal, family, or household
        purposes (“California Customers”) may request information about whether
        the business has disclosed personal information to any third parties for
        the third parties’ direct marketing purposes. California Customers may
        request further information about our compliance with this law by
        e-mailing support@herban.app. Please note that businesses are required
        to respond to one request per California Customer each year and may not
        be required to respond to requests made by means other than through the
        designated e-mail address.
      </p>

      <p className={classes["section-title"]}>
        Changes and Updates to this Privacy Notice
      </p>
      <p>
        From time to time, we may revise this Notice to reflect changes in our
        Services, how we use information, or applicable law. To help you stay
        current of any changes, we note the date the Privacy Notice was last
        updated above. If we make material changes to the Notice, we will
        provide notice or obtain consent regarding such changes as may be
        required by law.
      </p>

      <p className={classes["section-title"]}>HerbanApp Contact Information</p>
      <p>
        Please contact HerbanApp with any questions, concerns, complaints, or
        comments about this Notice, your information, our third-party disclosure
        practices, or your consent choices:
      </p>
      <p>HerbanApp LLC.</p>
      <p>Attention: Privacy Officer</p>
      <p>1216 Broadway 2nd FL</p>
      <p>New York, NY 10001</p>
      <p>Email: hi@herban.app</p>
    </Modal>
  );
};

export default PrivacyPolicy;
