import { motion } from "framer-motion";
import React from "react";
import { useWindowResize } from "../../hooks/useWindowResize";
import classes from "./index.module.scss";

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const Modal = (props: React.PropsWithChildren<Props>) => {
  const { isSmallDevice } = useWindowResize();
  React.useEffect(() => {
    if (props.isVisible) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [props.isVisible]);
  return (
    <>
      {props.isVisible && (
        <>
          <motion.div
            className={classes["modal-backdrop"]}
            onClick={() => {
              props.onClose();
            }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: "easeInOut",
              },
            }}
            exit={{
              opacity: 0,
            }}
          />
          <motion.div
            className={classes["modal-container"]}
            initial={{
              opacity: 0,
              top: "14vh",
            }}
            animate={{
              opacity: 1,
              top: "10vh",
              transition: {
                ease: "easeInOut",
              },
            }}
            exit={{
              opacity: 0,
              top: "14vh",
            }}
          >
            <div className={classes["content"]}>{props.children}</div>
          </motion.div>
        </>
      )}
    </>
  );
};

export default Modal;
