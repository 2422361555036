
    
    
    import icFacebook from './images/ic_facebook.png';
import icInstagram from './images/ic_instagram.png';
import icLinkedin from './images/ic_linkedin.png';
import icMerchant from './images/ic_merchant.png';
import icTwitter from './images/ic_twitter.png';
import ilBackground from './images/il_background.png';
import ilLogo from './images/il_logo.png';
import ilUnderline from './images/il_underline.png';

    const images = {
      dark: {},
      light: {},
      default: {icFacebook,icInstagram,icLinkedin,icMerchant,icTwitter,ilBackground,ilLogo,ilUnderline},
    };

    export default images;
    