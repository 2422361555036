import { firebaseDatabase } from "./../firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";

const submitDetails = async (data: { name: string; email: string }) => {
  try {
    const ref = await addDoc(
      collection(firebaseDatabase, "underconstruction_page_users"),
      {
        name: data.name,
        email: data.email,
        createdAt: new Date().toUTCString(),
      }
    );
    if (ref.id) {
      console.log("done");
      // console.log({ id: ref.id });
      return true;
    }
    return false;
  } catch (error) {
    console.log({ error });
    return false;
  }
};

export default submitDetails;
