import React from "react";
import HomePage from "./pages/HomePage";
import { Navigate, Route, Routes } from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import { logEvent } from "firebase/analytics";
import { firebaseAnalytics } from "./firebase";

const App = () => {
  React.useEffect(() => {
    logEvent(firebaseAnalytics, "app load");
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/privacy-policy" element={<HomePage />} />
      <Route path="/terms-of-service" element={<HomePage />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
