import React from "react";
import classes from "./index.module.scss";

interface Props {
  label: string;
  textarea?: boolean;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  required?: boolean;
}

const TextField = (props: Props) => {
  return (
    <div className={classes["text-field"]}>
      <p className={classes["label"]}>
        {props.label}
        {props?.required ? <span> *</span> : <></>}
      </p>
      {props.textarea ? (
        <>
          <textarea
            className={`${classes["input"]} ${classes["text-area"]}`}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          ></textarea>
        </>
      ) : (
        <>
          <input
            type="text"
            className={classes["input"]}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
          />
        </>
      )}
    </div>
  );
};

export default TextField;
